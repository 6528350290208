import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import PageHandler from '@jpp/shared/PageHandler';
import Page from '@jpp/templates/Page';
import { IReduxState } from 'common/redux/createStore';
import { IPageStoreState } from 'common/redux/page/reducer';
import { getPageFromState } from 'common/redux/page/selectors';
import { clearPage, getShopPage } from 'common/redux/rootActions';
import { EPageType } from 'common/typings/enums';
import {
  TFuncValueVoid,
  TFuncVoid,
  TReduxError,
  TTemplateInitialProps,
} from 'common/typings/types';
import { shouldPageError } from 'common/utils/shared/page';

interface IHomePageProps {
  slug: string;
  error?: TReduxError;
}

interface IStoreHomePageProps {
  page: IPageStoreState;
}

interface IDispatchHomePageProps {
  onClearPage: TFuncVoid;
  onGetShopPage: TFuncValueVoid<string>;
}

type THomePage = IHomePageProps & IStoreHomePageProps & IDispatchHomePageProps;

class HomePage extends PureComponent<THomePage> {
  static async getInitialProps({ store, res }: TTemplateInitialProps) {
    const dispatch = store.dispatch;
    await dispatch(getShopPage(EPageType.Home) as any);
    const state: IReduxState = store.getState();
    const page: IPageStoreState = getPageFromState(state);

    await shouldPageError(page, res, dispatch);

    return { slug: EPageType.Home, page };
  }

  componentDidMount = () => {
    if (this.props.error) {
      this.props.onGetShopPage(EPageType.Home);
    }
  };

  componentWillUnmount = () => {
    this.props.onClearPage();
  };

  render() {
    return <PageHandler {...this.props} template={Page} />;
  }
}

const mapDispatchToProps: IDispatchHomePageProps = {
  onClearPage: clearPage,
  onGetShopPage: getShopPage,
};

export default connect<
  IStoreHomePageProps,
  IDispatchHomePageProps,
  IHomePageProps
>(
  null,
  mapDispatchToProps
)(HomePage);
