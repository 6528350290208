import React from 'react';

import AcfComponents from '@jpp/organisms/AcfComponents';
import ErrorTemplate from '@jpp/templates/Error';
import { IPageTemplateProps } from 'common/typings/types';
import { SERVER_ERROR_STATUS_CODE } from 'common/utils/constants';

const Page: React.FunctionComponent<IPageTemplateProps> = ({ page }) => {
  const acf = page.acf || page.acf_fields;
  if (!acf) {
    return <ErrorTemplate statusCode={SERVER_ERROR_STATUS_CODE} />;
  }
  return (
    <AcfComponents components={acf?.components} page_theme={acf?.page_theme} />
  );
};

export default Page;
