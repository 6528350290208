import { Dispatch } from 'redux';

import { clearPage } from 'common/redux/rootActions';
import { EStatus } from 'common/typings/enums';
import { TNextServerResponse } from 'common/typings/types';
import { NOT_FOUND_STATUS_CODE } from 'common/utils/constants';

export const shouldPageError = async (
  page: any,
  res: TNextServerResponse,
  dispatch: Dispatch
): Promise<{ error: true } | undefined> => {
  const hasStatus = !!page?.status;

  if (
    (Object.keys(page).length === 0 ||
      (hasStatus && page.status !== EStatus.Publish)) &&
    res
  ) {
    res.statusCode = NOT_FOUND_STATUS_CODE;
    await dispatch(clearPage());

    return {
      error: true,
    };
  }

  return;
};
